<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3">
            <strong>Closed Dates:</strong>
            <br>
            <span v-for="date in this.closedDates" v-bind:key="date">{{ date }}<br></span>
        </nav>
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <HRMessage @newMessage="refreshMessage" :message="message" />
            <ClockInOut />
            <MiscTask />
            <Recognition />
        </main>
    </div>
</template>

<script>
    import { store } from "@/store/Tool.store";
    import HRMessage from "@/components/tools/TimeClock/HRMessage";
    import ClockInOut from "@/components/tools/TimeClock/ClockInOut";
    import MiscTask from "@/components/tools/TimeClock/MiscTask";
    import Recognition from "@/components/tools/TimeClock/Recognition";

    export default {
        name: "TimeClock",
        components: {
            Recognition,
            MiscTask,
            ClockInOut,
            HRMessage
        },
        data() {
            return {
                closedDates: [],
                message: null,
            }
        },
        methods: {
            refreshMessage: function (message) {
                this.message = message;
            }
        },
        created() {
            store.api('/myuser/get_timeclock_json/').then(results => {
                this.closedDates = results.closed_dates;
                this.message = results.time_clock_message;
            });
        }
    }
</script>

<style scoped>

</style>